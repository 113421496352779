import "./CabinetStyles.css";

import Navbar from '../../compose/Navbar/Navbar';
import Footer from '../../compose/Footer/Footer'
import Thumbnail from "../../components/Gallery/Thumbnail";

import { useNavigate } from 'react-router-dom';

import { Helmet } from 'react-helmet';

const FaceFramedPage = () => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate("/contact");
    }

    return (
        <>
            <Helmet>
                <title>Face Frame Cabinets | River City Cabinets</title>
                <meta name="description" content="Get inspired with our face-frame cabinets available for your dream project!"></meta>
                <link rel="canonical" href="https://www.rivercitycabinetsidaho.com/face-frame-cabinets/"></link>
            </Helmet>
            <Navbar />
            <section className="face-framed-gallery">
                <div className="title">
                    <h1>Face Frame Cabinets</h1>
                    <hr />
                    <p>All styles of our face-framed cabinets are available to help you build your dream ambiance.</p>
                </div>
                <div className="content">
                    <div className="category">
                        <div className="category-name">
                            <h2>Face Frame Collection</h2>
                        </div>
                        <div className="items">
                            <Thumbnail item='slim-dove-white' lightbox={true} badgeText='New!' />
                            <Thumbnail item='sage-green' lightbox={true} badgeText='New!' />
                            <Thumbnail item='slim-white-oak' lightbox={true} badgeText='New!' />
                            <Thumbnail item='shaker-honey' lightbox={true} badgeText='New!' />
                            <Thumbnail item='oxford' lightbox={true} />
                            <Thumbnail item='toffee-door' lightbox={true} />
                            <Thumbnail item='pigeon-white' lightbox={true} />
                            <Thumbnail item='shaker-cream' lightbox={true} />
                            <Thumbnail item='pure-white' lightbox={true} />
                            <Thumbnail item='shaker-dove' lightbox={true} />
                            <Thumbnail item='pebble-grey' lightbox={true} />
                            <Thumbnail item='pebble-stone' lightbox={true} />
                            <Thumbnail item='shaker-black-door' lightbox={true} />
                            <Thumbnail item='shaker-cinder' lightbox={true} />
                            <Thumbnail item='espresso' lightbox={true} />
                            <Thumbnail item='blue-shaker' lightbox={true} />
                            <Thumbnail item='saddle' lightbox={true} badgeText='Discontinued' badgeColor={'var(--danger-color)'} />
                            <Thumbnail item='moonshine' lightbox={true} />
                            <Thumbnail item='torrance-white' lightbox={true} />
                            <Thumbnail item='antique-cream' lightbox={true} />
                            <Thumbnail item='cognac' lightbox={true} />
                        </div>
                    </div>
                </div>
                <div className="cta">
                    <button className="primary-button" onClick={handleButtonClick}>Contact Now</button>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default FaceFramedPage;