import './Thumbnail.css';

import React, { useState } from "react";
import FsLightbox from "fslightbox-react";

import FaceFramedThumb from '../../assets/imgs/gallery/oxford/1.webp';
import EuroBoxThumb from '../../assets/imgs/gallery/light-grey/1.webp';
import AntiqueCreamThumb from '../../assets/imgs/gallery/antique-cream/1.webp';
import AntiqueCreamDoorThumb from '../../assets/imgs/gallery/antique-cream-door/AntiqueCream.webp';
import BlueShakerThumb from '../../assets/imgs/gallery/blue-shaker/1.webp';
import CognacThumb from 'assets/imgs/gallery/cognac/1.webp';
import CognacDoorThumb from 'assets/imgs/gallery/cognac-door/Cognac.webp';
import EspressoThumb from '../../assets/imgs/gallery/espresso/1.webp';
import MoonshineThumb from '../../assets/imgs/gallery/moonshine/1.webp';
import PebbleGreyThumb from '../../assets/imgs/gallery/pebble-grey/1.webp';
import PebbleStoneThumb from '../../assets/imgs/gallery/pebble-stone/1.webp';
import PebbleStoneDoorThumb from '../../assets/imgs/gallery/pebble-stone-door/PebbleStone.webp';
import PigeonWhiteThumb from '../../assets/imgs/gallery/pigeon-white/1.webp';
import PureWhiteThumb from '../../assets/imgs/gallery/pure-white/1.webp';
import PureWhiteDoorThumb from '../../assets/imgs/gallery/pure-white-door/PureWhiteShaker.webp';
import SaddleThumb from '../../assets/imgs/gallery/saddle/1.webp';
import ShakerBlackDoorThumb from '../../assets/imgs/gallery/shaker-black-door/ShakerBlack.webp';
import ShakerCinderThumb from '../../assets/imgs/gallery/shaker-cinder/1.webp';
import ShakerCinderDoorThumb from '../../assets/imgs/gallery/shaker-cinder-door/ShakerCinder.webp';
import ShakerCreamThumb from '../../assets/imgs/gallery/shaker-cream/1.webp';
import ShakerCreamDoorThumb from '../../assets/imgs/gallery/shaker-cream-door/ShakerCream.webp';
import ShakerDoveThumb from '../../assets/imgs/gallery/shaker-dove/1.webp';
import ShakerDoveDoorThumb from '../../assets/imgs/gallery/shaker-dove-door/ShakerDove.webp';
import ShakerHoneyThumb from '../../assets/imgs/gallery/shaker-honey/1.webp';
import OxfordThumb from '../../assets/imgs/gallery/oxford/1.webp';
import OxfordDoorThumb from '../../assets/imgs/gallery/oxford-door/Oxford.webp';
import KitchenThumb from '../../assets/imgs/services/kitchen.webp';
import BathroomThumb from '../../assets/imgs/services/bathroom.webp';
import FlooringThumb from '../../assets/imgs/services/flooring.webp';
import CountertopThumb from '../../assets/imgs/gallery/shaker-dove/4.webp';
import HoodsThumb from '../../assets/imgs/gallery/hoods/asheville-1/1.webp';
import DarkGreyGlossThumb from '../../assets/imgs/gallery/dark-grey-gloss/1.webp';
import GlossyWhiteThumb from '../../assets/imgs/gallery/glossy-white/1.webp';
import LightGreyThumb from '../../assets/imgs/gallery/light-grey/1.webp';
import DarkGrainThumb from '../../assets/imgs/gallery/dark-grain/1.webp';
import ToffeeDoorThumb from '../../assets/imgs/gallery/toffee-door/1.webp';
import TorranceWhiteThumb from '../../assets/imgs/gallery/torrance-white/1.webp';
import TorranceWhiteDoorThumb from '../../assets/imgs/gallery/torrance-white-door/TorranceWhite.webp';
import WhiteOakThumb from '../../assets/imgs/gallery/white-oak/1.webp';
import SageGreenThumb from '../../assets/imgs/gallery/sage-green/1.webp';
import SlimDoveWhiteThumb from '../../assets/imgs/gallery/slim-dove-white/1.webp';
import SlimWhiteOakThumb from '../../assets/imgs/gallery/slim-white-oak/1.webp';

import Asheville1Thumb from '../../assets/imgs/gallery/hoods/asheville-1/1.webp';
import Asheville2Thumb from '../../assets/imgs/gallery/hoods/asheville-2/1.webp';
import Asheville3Thumb from '../../assets/imgs/gallery/hoods/asheville-3/1.webp';
import Charlotte1Thumb from '../../assets/imgs/gallery/hoods/charlotte-1/1.webp';
import Charlotte2Thumb from '../../assets/imgs/gallery/hoods/charlotte-2/1.webp';
import Charlotte3Thumb from '../../assets/imgs/gallery/hoods/charlotte-3/1.webp';
import Raleigh1Thumb from '../../assets/imgs/gallery/hoods/raleigh-1/1.webp';
import Raleigh2Thumb from '../../assets/imgs/gallery/hoods/raleigh-2/1.webp';
import Raleigh3Thumb from '../../assets/imgs/gallery/hoods/raleigh-3/1.webp';
import Wilmington1Thumb from '../../assets/imgs/gallery/hoods/wilmington-1/1.webp';
import Wilmington2Thumb from '../../assets/imgs/gallery/hoods/wilmington-2/1.webp';
import Wilmington3Thumb from '../../assets/imgs/gallery/hoods/wilmington-3/1.webp';
import Wilmington4Thumb from '../../assets/imgs/gallery/hoods/wilmington-4/1.webp';

import ServicesThumb from '../../assets/imgs/services/services.webp';
import GalleryThumb from 'assets/imgs/gallery/gallery-thumb.webp';

import FaceFramedIcon from '../../assets/imgs/services/icons/kitchen.png';
import EuroBoxIcon from '../../assets/imgs/services/icons/kitchen.png';
import KitchenIcon from '../../assets/imgs/services/icons/kitchen.png';
import BathroomIcon from '../../assets/imgs/services/icons/bathroom.png';
import FlooringIcon from '../../assets/imgs/services/icons/flooring.png';
import CountertopIcon from '../../assets/imgs/services/icons/countertop.png';
import HoodsIcon from '../../assets/imgs/services/icons/hood.png';

import ServicesIcon from '../../assets/imgs/services/icons/services.png';
import GalleryIcon from '../../assets/imgs/services/icons/gallery.png';

import Badge from '../Badges/Badge';

const Thumbnail = props => {
    let thumbnail;
    let icon = '';
    let legend;
    let lightbox = props.lightbox || false;
    let caption = [];
    const cache = [];

    const [toggler, setToggler] = useState(false);

    switch(props.item) {
        case 'face-framed':
            thumbnail = FaceFramedThumb;
            legend = 'Face Frame';
            icon = FaceFramedIcon;
            importAll(require.context('assets/imgs/gallery/tier-1', false, /\.webp$/));
            break;
        case 'euro-box':
            thumbnail = EuroBoxThumb;
            legend = 'Frameless Euro';
            icon = EuroBoxIcon;
            importAll(require.context('assets/imgs/gallery/tier-2', false, /\.webp$/));
            break;
        case 'antique-cream':
            thumbnail = AntiqueCreamThumb;
            legend = 'Antique Cream';
            importAll(require.context('assets/imgs/gallery/antique-cream', false, /\.webp$/));
            break;
        case 'antique-cream-door':
            thumbnail = AntiqueCreamDoorThumb;
            legend = 'Antique Cream';
            importAll(require.context('assets/imgs/gallery/antique-cream-door', false, /\.webp$/));
            break;
        case 'blue-shaker':
            thumbnail = BlueShakerThumb;
            legend = 'Blue Shaker';
            importAll(require.context('assets/imgs/gallery/blue-shaker', false, /\.webp$/));
            break;
        case 'cognac':
            thumbnail = CognacThumb;
            legend = 'Cognac';
            importAll(require.context('assets/imgs/gallery/cognac', false, /\.webp$/));
            break;
        case 'cognac-door':
            thumbnail = CognacDoorThumb;
            legend = 'Cognac';
            importAll(require.context('assets/imgs/gallery/cognac-door', false, /\.webp$/));
            break;
        case 'dark-grain':
            thumbnail = DarkGrainThumb;
            legend = 'Dark Grain';
            importAll(require.context('assets/imgs/gallery/dark-grain', false, /\.webp$/));
            break;
        case 'dark-grey-gloss':
            thumbnail = DarkGreyGlossThumb;
            legend = 'Dark Grey Gloss';
            importAll(require.context('assets/imgs/gallery/dark-grey-gloss', false, /\.webp$/));
            break;
        case 'espresso':
            thumbnail = EspressoThumb;
            legend = 'Espresso';
            importAll(require.context('assets/imgs/gallery/espresso', false, /\.webp$/));
            break;
        case 'glossy-white':
            thumbnail = GlossyWhiteThumb;
            legend = 'Glossy White';
            importAll(require.context('assets/imgs/gallery/glossy-white', false, /\.webp$/));
            break;
        case 'light-grey':
            thumbnail = LightGreyThumb;
            legend = 'Light Grey';
            importAll(require.context('assets/imgs/gallery/light-grey', false, /\.webp$/));
            break;
        case 'moonshine':
            thumbnail = MoonshineThumb;
            legend = 'Moonshine';
            importAll(require.context('assets/imgs/gallery/moonshine', false, /\.webp$/));
            break;
        case 'pebble-grey':
            thumbnail = PebbleGreyThumb;
            legend = 'Pebble Grey';
            importAll(require.context('assets/imgs/gallery/pebble-grey', false, /\.webp$/));
            break;
        case 'pebble-stone':
            thumbnail = PebbleStoneThumb;
            legend = 'Pebble Stone';
            importAll(require.context('assets/imgs/gallery/pebble-stone', false, /\.webp$/));
            break;
        case 'pebble-stone-door':
            thumbnail = PebbleStoneDoorThumb;
            legend = 'Pebble Stone';
            importAll(require.context('assets/imgs/gallery/pebble-stone-door', false, /\.webp$/));
            break;
        case 'pigeon-white':
            thumbnail = PigeonWhiteThumb;
            legend = 'Pigeon White';
            importAll(require.context('assets/imgs/gallery/pigeon-white', false, /\.webp$/));
            break;
        case 'pure-white':
            thumbnail = PureWhiteThumb;
            legend = 'Pure White';
            importAll(require.context('assets/imgs/gallery/pure-white', false, /\.webp$/));
            break;
        case 'pure-white-door':
            thumbnail = PureWhiteDoorThumb;
            legend = 'Pure White';
            importAll(require.context('assets/imgs/gallery/pure-white-door', false, /\.webp$/));
            break;
        case 'saddle':
            thumbnail = SaddleThumb;
            legend = 'Saddle';
            importAll(require.context('assets/imgs/gallery/saddle', false, /\.webp$/));
            break;
        case 'sage-green':
            thumbnail = SageGreenThumb;
            legend = 'Sage Green';
            importAll(require.context('assets/imgs/gallery/sage-green', false, /\.webp$/));
            break;
        case 'shaker-black-door':
            thumbnail = ShakerBlackDoorThumb;
            legend = 'Shaker Black';
            importAll(require.context('assets/imgs/gallery/shaker-black-door', false, /\.webp$/));
            break;
        case 'shaker-cinder':
            thumbnail = ShakerCinderThumb;
            legend = 'Shaker Cinder';
            importAll(require.context('../../assets/imgs/gallery/shaker-cinder', false, /\.webp$/));
            break;
        case 'shaker-cinder-door':
            thumbnail = ShakerCinderDoorThumb;
            legend = 'Shaker Cinder';
            importAll(require.context('../../assets/imgs/gallery/shaker-cinder-door', false, /\.webp$/));
            break;
        case 'shaker-cream':
            thumbnail = ShakerCreamThumb;
            legend = 'Shaker Cream';
            importAll(require.context('../../assets/imgs/gallery/shaker-cream', false, /\.webp$/));
            break;
        case 'shaker-cream-door':
            thumbnail = ShakerCreamDoorThumb;
            legend = 'Shaker Cream';
            importAll(require.context('../../assets/imgs/gallery/shaker-cream-door', false, /\.webp$/));
            break;
        case 'shaker-dove':
            thumbnail = ShakerDoveThumb;
            legend = 'Shaker Dove';
            importAll(require.context('../../assets/imgs/gallery/shaker-dove', false, /\.webp$/));
            break;
        case 'shaker-dove-door':
            thumbnail = ShakerDoveDoorThumb;
            legend = 'Shaker Dove';
            importAll(require.context('../../assets/imgs/gallery/shaker-dove-door', false, /\.webp$/));
            break;
        case 'shaker-honey':
            thumbnail = ShakerHoneyThumb;
            legend = 'Shaker Honey';
            importAll(require.context('../../assets/imgs/gallery/shaker-honey', false, /\.webp$/));
            break;
        case 'slim-dove-white':
            thumbnail = SlimDoveWhiteThumb;
            legend = 'Slim Dove White';
            importAll(require.context('../../assets/imgs/gallery/slim-dove-white', false, /\.webp$/));
            break;
        case 'slim-white-oak':
            thumbnail = SlimWhiteOakThumb;
            legend = 'Slim White Oak';
            importAll(require.context('../../assets/imgs/gallery/slim-white-oak', false, /\.webp$/));
            break;
        case 'oxford':
            thumbnail = OxfordThumb;
            legend = 'Oxford';
            importAll(require.context('../../assets/imgs/gallery/oxford', false, /\.webp$/));
            break;
        case 'oxford-door':
            thumbnail = OxfordDoorThumb;
            legend = 'Oxford';
            importAll(require.context('../../assets/imgs/gallery/oxford-door', false, /\.webp$/));
            break;
        case 'toffee-door':
            thumbnail = ToffeeDoorThumb;
            legend = 'Toffee';
            importAll(require.context('../../assets/imgs/gallery/toffee-door', false, /\.webp$/));
            break;
        case 'torrance-white':
            thumbnail = TorranceWhiteThumb;
            legend = 'Torrance White';
            importAll(require.context('../../assets/imgs/gallery/torrance-white', false, /\.webp$/));
            break;
        case 'torrance-white-door':
            thumbnail = TorranceWhiteDoorThumb;
            legend = 'Torrance White';
            importAll(require.context('../../assets/imgs/gallery/torrance-white-door', false, /\.webp$/));
            break;
        case 'white-oak':
            thumbnail = WhiteOakThumb;
            legend = 'White Oak';
            importAll(require.context('../../assets/imgs/gallery/white-oak', false, /\.webp$/));
            break;
        case 'asheville-1':
            thumbnail = Asheville1Thumb;
            legend = 'Asheville - Curved Classic Moulding Smooth';
            icon = HoodsIcon;
            importAll(require.context('../../assets/imgs/gallery/hoods/asheville-1', false, /\.webp$/));
            break;
        case 'asheville-2':
            thumbnail = Asheville2Thumb;
            legend = 'Asheville - Curved Classic Moulding Strapped';
            icon = HoodsIcon;
            importAll(require.context('../../assets/imgs/gallery/hoods/asheville-2', false, /\.webp$/));
            break;
        case 'asheville-3':
            thumbnail = Asheville3Thumb;
            legend = 'Asheville - Curved Strapped Moulding';
            icon = HoodsIcon;
            importAll(require.context('../../assets/imgs/gallery/hoods/asheville-3', false, /\.webp$/));
            break;
        case 'charlotte-1':
            thumbnail = Charlotte1Thumb;
            legend = 'Charlotte - Sloped Classic Moulding Smooth';
            icon = HoodsIcon;
            importAll(require.context('../../assets/imgs/gallery/hoods/charlotte-1', false, /\.webp$/));
            break;
        case 'charlotte-2':
            thumbnail = Charlotte2Thumb;
            legend = 'Charlotte - Sloped Classic Moulding Strapped';
            icon = HoodsIcon;
            importAll(require.context('../../assets/imgs/gallery/hoods/charlotte-2', false, /\.webp$/));
            break;
        case 'charlotte-3':
            thumbnail = Charlotte3Thumb;
            legend = 'Charlotte - Sloped Strapped Moulding';
            icon = HoodsIcon;
            importAll(require.context('../../assets/imgs/gallery/hoods/charlotte-3', false, /\.webp$/));
            break;
        case 'raleigh-1':
            thumbnail = Raleigh1Thumb;
            legend = 'Raleigh - Tapered Block Moulding Shiplap';
            icon = HoodsIcon;
            importAll(require.context('../../assets/imgs/gallery/hoods/raleigh-1', false, /\.webp$/));
            break;
        case 'raleigh-2':
            thumbnail = Raleigh2Thumb;
            legend = 'Raleigh - Tapered Classic Moulding Smooth';
            icon = HoodsIcon;
            importAll(require.context('../../assets/imgs/gallery/hoods/raleigh-2', false, /\.webp$/));
            break;
        case 'raleigh-3':
            thumbnail = Raleigh3Thumb;
            legend = 'Raleigh - Tapered Strapped Moulding';
            icon = HoodsIcon;
            importAll(require.context('../../assets/imgs/gallery/hoods/raleigh-3', false, /\.webp$/));
            break;
        case 'wilmington-1':
            thumbnail = Wilmington1Thumb;
            legend = 'Wilmington - Angled Classic Moulding Smooth';
            icon = HoodsIcon;
            importAll(require.context('../../assets/imgs/gallery/hoods/wilmington-1', false, /\.webp$/));
            break;
        case 'wilmington-2':
            thumbnail = Wilmington2Thumb;
            legend = 'Wilmington - Angled Maple Band Smooth';
            icon = HoodsIcon;
            importAll(require.context('../../assets/imgs/gallery/hoods/wilmington-2', false, /\.webp$/));
            break;
        case 'wilmington-3':
            thumbnail = Wilmington3Thumb;
            legend = 'Wilmington - Angled Strapped Moulding';
            icon = HoodsIcon;
            importAll(require.context('../../assets/imgs/gallery/hoods/wilmington-3', false, /\.webp$/));
            break;
        case 'wilmington-4':
            thumbnail = Wilmington4Thumb;
            legend = 'Wilmington - Angled Walnut Band Smooth';
            icon = HoodsIcon;
            importAll(require.context('../../assets/imgs/gallery/hoods/wilmington-4', false, /\.webp$/));
            break;
        case 'kitchen':
            thumbnail = KitchenThumb;
            legend = 'Kitchen';
            icon = KitchenIcon;
            break;
        case 'bathroom':
            thumbnail = BathroomThumb;
            legend = 'Bathroom';
            icon = BathroomIcon;
            break;
        case 'flooring':
            thumbnail = FlooringThumb;
            legend = 'Flooring';
            icon = FlooringIcon;
            break;
        case 'countertops':
            thumbnail = CountertopThumb;
            legend = 'Countertops';
            icon = CountertopIcon;
            break;
        case 'hoods':
            thumbnail = HoodsThumb;
            legend = 'Hoods';
            icon = HoodsIcon;
            break;
        case 'services':
            thumbnail = ServicesThumb;
            legend = 'Services';
            icon = ServicesIcon;
            break;
        case 'gallery':
            thumbnail = GalleryThumb;
            legend = 'Gallery';
            icon = GalleryIcon;
            break;
        default:
            thumbnail = '';
            icon = '';
    }

    function DisplayIcon() {
        if (icon !== '') {
            return <img src={ icon } alt={ legend } />;
        }
        return null;
    }

    function DisplayLightBox() {
        if (lightbox) {
            setToggler(!toggler);
        }
    }
    
    function importAll(r) {
        r.keys().forEach(key => {
            cache.push(r(key))
            caption.push(legend);
        });
    }

    return (
        <div className='Thumbnail'>
            <FsLightbox
                toggler={toggler}
                sources={cache.slice(0, Math.ceil(cache.length / 2))}
                captions={caption}
                disableLocalStorage={true}
                disableSlideshowLoop={true}
            />
            <div className={`item ${props.item} ${props.cover ? "cover" : ""} ${props.noCursor ? "no-cursor" : ""} ${props.door ? "door" : ""}`} onClick={DisplayLightBox} >
                <div style={{
                        background: `url(${thumbnail})`,
                        backgroundSize: 'cover', 
                        backgroundPosition: 'center'
                    }}
                    className="image lazy"
                >
                    {props.badgeText ? (
                        <Badge badgeText={props.badgeText} badgeColor={props.badgeColor} />
                    ) :
                        <></>
                    }
                    <div className="legend">
                        <div className="icon">
                            <DisplayIcon />
                        </div>
                        <div className="description">
                            <p>{ legend }</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Thumbnail;